@use "../base/summarize" as * ;

// 職種紹介
.careers-job {
	padding: 6rem 0 8rem;
	background-color: #F9F9F9;
	@include wide {
		padding: 8rem 0 10rem;
	}
	&__heading {
		text-align: center;
		font-size: 2.4rem;
		font-weight: 700;
		line-height: 150%;
		letter-spacing: 0.24rem;
		margin-bottom: 3.6rem;
		@include wide {
			font-size: 2.8rem;;
			letter-spacing: 0.28rem;
			margin-bottom: 3.8rem;
		}
	}
	&__list {
		margin-bottom: 3rem;
		@include wide {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-bottom: 5rem;
		}
	}
	&__item {
		cursor: pointer;
		background-color: $white;
		box-shadow: $box_shadow;
		margin-bottom: 2rem;
		@include wide {
			transition: all ease 0.3s;
			width: calc((100% - 5.6rem) / 3);
		}
		&:last-of-type {
			margin-bottom: 0;
			@include wide {
				margin-bottom: 2rem;
			}
		}
		&:hover {
			@include wide {
				box-shadow: $box_shadow_hover
			}
		}
	}
	&__item-link {
		position: relative;
		display: block;
		padding: 2rem;
		@include wide {
			padding: 2rem 6rem 2rem 2rem;
		}
		&::after {
			position: absolute;
			top: 3rem;
			right: 2rem;
			display: block;
			content: '';
			background-image: url(/assets/img/common/arrow-next-red.svg);
			background-size: contain;
			background-repeat: no-repeat;
			width: 2rem;
			height: 0.8rem;
		}
	}
	&__item-title {
		color: $black;
		font-size: 1.6rem;
		font-weight: 700;
		line-height: 175%;
		letter-spacing: 0.032rem;
		margin-bottom: 1.2rem;
		@include wide {
			font-size: 1.8rem;
			letter-spacing: 0.036rem;
		}
	}
	&__second-list-title {
		color: $black;
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 175%;
		letter-spacing: 0.028rem;
	}
	&__second-item {
		color: $black;
		font-size: 1.3rem;
		font-weight: 400;
		line-height: 175%;
		letter-spacing: 0.026rem;
	}
	&__second-item-small {
		font-size: 1.1rem;
		letter-spacing: 0.022rem;
		@include wide {
			font-size: 1.3rem;
			letter-spacing: 0.026rem;
		}
	}
	&__button {
		width: 26.4rem;
		margin: 0 auto;
		@include wide {
			width: 36.8rem;
		}
	}
}

//ここからcareers-mv
.careers-mv {
	position: relative;
	height: 375px;

	@include wide {
		height: auto;
	}
}
.careers-mv__inner {
	position: relative;
	z-index: 1;
	align-items: unset;
	padding-bottom: 0;

	@include wide {
		max-width: 1188px;
	}
}
.careers-mv__heading {
	font-size: 2rem;
	line-height: 1.75;
	position: relative;
	padding-top: 15px;
	padding-left: 30px;
	letter-spacing: .05em;
	color: $black;

	@include wide {
		position: absolute;
		top: 15%;
		z-index: 1;
		left: 50%;
		transform: translateX(-50%);
		font-size: 2.8rem;
		width: 100%;
		padding-top: 0;
		padding-left: 0;
		letter-spacing: .02em;
		text-align: center;
	}
	@include carrer-mv {
		font-size: 2.2rem;
	}
	@media (min-width: (600px)) and (max-width: (800px)) {
		font-size: 1.8rem;
	}
}
.careers-mv .under-mv__image {
	@include wide {
		position: relative;
		z-index: 0;
	}
	img {
		@include wide {
			object-fit: contain;
			object-position: center;
		}
	}
}
.careers-mv .breadcrumbs {
	@include wide {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
}
.careers-mv__button {
	opacity: 0;
	transition: .3s opacity;
	pointer-events: none;
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	z-index: 100;
	width: 22.7rem;
	height: 6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10rem;
	background-color: $red;
	color: $white;
	font-size: 1.6rem;
	font-weight: 700;
	box-shadow: 2rem 2rem 3rem 0 rgba(16, 15, 24, 0.30);
	@include wide {
		bottom: 2rem;
		right: 2rem;
		width: 27rem;
		height: 8rem;
		font-size: 1.8rem;
	}
	&--active {
		opacity: 1;
		pointer-events: auto;
	}
	&:before {
		position: absolute;
		content: "";
		width: 2rem;
		height: 0.8rem;
		right: 2rem;
		top: 50%;
		transform: translateY(-50%);
		background-image: url(/assets/img/common/arrow-floating.svg);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		transition: .3s right;
		@include wide {
			width: 2.5rem;
			height: 1rem;
		}
	}
	&:hover {
		&::before {
			@include wide {
				right: 1.5rem;
			}
		}
	}
}

//ここからcareers-work
.careers-work {
	height: 490px;
	background: url("/assets/img/careers/bg01_sp.jpg");
	background-position: center;
	background-size: cover;

	@include wide {
		height: 400px;
		background: url("/assets/img/careers/bg01_pc.jpg");
		background-position: center;
		background-size: cover;
	}
	&--message {
		background-image: url("/assets/img/careers/bg02_sp.png");
		@include wide {
			background-image: url("/assets/img/careers/bg02_pc.png");
		}
	}
}
.careers-work__inner {
	padding-top: 85px;

	@include wide {
		padding-top: 96px;
	}
}
.careers-work__textarea {
	margin-bottom: 69px;

	@include wide {
		margin-bottom: 31px;
		margin-left: 104px;
	}
}
.careers-work__heading {
	font-size: 2.4rem;
	line-height: 1.5;
	margin-bottom: 37px;
	text-align: center;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.8rem;
		margin-bottom: 38px;
		text-align: left;
	}
}
.careers-work__text {
	font-size: 1.8rem;
	line-height: 1.75;
	text-align: left;
	letter-spacing: .02em;
}
.careers-work__button {
	width: 206px;
	height: 48px;
	margin-right: auto;
	margin-left: auto;

	@include wide {
		margin-left: 104px;
	}
}

//ここからcareers-interview
.careers-interview {
	background: #fff;
}
.careers-interview__inner {
	padding: 6rem 0 6.2rem;

	@include wide {
		max-width: calc(1256px + #{$side-padding-pc} * 2);
		padding: 10rem 0 8rem;
	}
}
.careers-interview__textarea {
	margin-bottom: 0;
	padding-right: 20px;
	padding-left: 20px;

	@include wide {
		margin-bottom: 40px;
		padding-right: 0;
		padding-left: 0;
	}
}
.careers-interview__heading {
	font-size: 2.4rem;
	line-height: 1.5;
	margin-bottom: 28px;
	text-align: center;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.8rem;
		margin-bottom: 40px;
	}
}
.careers-interview__text {
	font-size: 1.8rem;
	line-height: 1.75;
	text-align: left;
	letter-spacing: .02em;

	@include wide {
		text-align: center;
	}
}
.careers-interview__box {
	@include sp {
		margin-top: 30px;
	}
	@include wide {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: calc(100% - #{$side-padding-pc} * 2);
		margin-right: auto;
		margin-left: auto;
	}
}
.careers-interview__item {
	position: relative;
	display: block;
	@include sp {
		margin-top: 60px;
		&:first-child {
			margin-top: 0;
		}
		&--05 {
			margin-top: 95px;
		}
		&--06 {
			margin-top: 20px;
		}
		&--07 {
			margin-top: 20px;
		}
		&--08 {
			margin-top: 40px;
		}
		&--09 {
			margin-top: 20px;
		}
		&--10 {
			margin-top: 60px;
		}
		&--11 {
			margin-top: 50px;
		}
	}
	@include wide {
		width: 50%;
		margin-bottom: 38px;
		padding-top: 46%;
	}
	@include pc {
		width: calc(100% / 3);
		padding-top: 31%;
	}
	&:nth-of-type(n+5) {
		@include tab {
			margin-bottom: 0;
		}
	}
	&:nth-of-type(n+4) {
		@include pc {
			margin-bottom: 0;
		}
	}
}
.careers-interview__imagearea {
	pointer-events: none;
	@include sp {
		position: relative;
		padding-top: 60%;
		margin-bottom: 30px;
	}
	@include wide {
		position: absolute;
		top: 0;
		left: 50%;
		width: 113.698%;
		margin-bottom: 13px;
		padding-top: 88.5%;
		transform: translateX(-50%);
	}
}
.careers-interview__image {
	@include sp {
		position: absolute;
		bottom: 30%;
		left: 0;
		width: 100%;
		height: 100%;
	}
	@include wide {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: transform .3s ;
		transform: scale(.9,.9);
		a:hover & {
			transform: scale(1,1);
		}
	}
	img {
		width: 100%;
	}
}
.careers-interview__wrap {
	position: relative;
	top: -10px;
	padding-right: 20px;
	padding-left: 20px;
	text-align: center;

	@include wide {
		top: -4px;
		padding-right: 0;
		padding-left: 0;
	}
}
.careers-interview__title {
	font-size: 1.8rem;
	line-height: 1.5;
	margin-bottom: 12px;
	letter-spacing: .05em;

	@include wide {
		font-size: 2rem;
		letter-spacing: 0;
	}
	@include pc {
		margin-bottom: 15px;
	}
}
.careers-interview__name {
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 1.5;
	margin-bottom: 4px;
	letter-spacing: 0;

	@include wide {
		font-size: 2rem;
	}
	@include pc {
		margin-bottom: 5px;
	}
}
.careers-interview__name-sub {
	font-size: 1.2rem;
	line-height: 1.5;
	letter-spacing: .02em;

	@include wide {
		font-size: 1.4rem;
	}
}

// ここからcareers-banner
.careers-banner {
	padding: 6rem 0;
	background-color: #F0F0F0;
	&__link {
		position: relative;
		display: block;
		width: 100%;
		height: 18rem;
		background-image: url(/assets/img/careers/banner-sp.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		padding: 4.6rem 0 0 14.2rem;
		margin: 0 auto;
		@include wide {
			width: 57.6rem;
			height: 18rem;
			background-image: url(/assets/img/careers/banner-pc.jpg);
			padding: 3.8rem 0 0 31.2rem;
		}
		&::after {
			position: absolute;
			right: 2rem;
			bottom: 1.6rem;
			display: block;
			content: '';
			background-image: url(/assets/img/common/arrow-next-red.svg);
			background-size: contain;
			background-repeat: no-repeat;
			width: 2rem;
			height: 0.8rem;
		}
	}
	&__heading {
		color: $white;
		font-size: 1.8rem;
		font-weight: 700;
		line-height: 160%;
		letter-spacing: 0.036rem;
		margin-bottom: 1.6rem;
		@include wide {
			font-size: 2rem;
			font-weight: 700;
			line-height: 150%;
			letter-spacing: 0.04rem;
		}
	}
	&__name-text {
		color: $white;
		font-size: 1.2rem;
		font-weight: 700;
		letter-spacing: 0.024rem;
		@include wide {
			font-size: 1.6rem;
			letter-spacing: 0.032rem;
		}
	}
}

// ここからcareers-question
.careers-question {
	font-size: 2.4rem;
	line-height: 1.5;
	padding-bottom: 46px;
	letter-spacing: .1em;
	background: #fff;

	@include wide {
		font-size: 2.8rem;
		padding-bottom: 62px;
	}
}
.careers-question__bg {
	width: 100%;
	background: $bg_gray;
}
.careers-question__inner {
	padding-top: 60px;
	padding-bottom: 80px;
	background: $bg_gray;

	@include wide {
		padding-top: 90px;
		padding-bottom: 130px;
	}
}
.careers-question__heading {
	margin-bottom: 34px;

	@include wide {
		margin-bottom: 77px;
	}
}
.careers-question__menu {
	flex-direction: column;

	@include pc {
		flex-direction: row;
	}
}
.careers-question__item {
	width: 100%;
	height: 60px;
	margin-right: 0;
	margin-bottom: 10px;

	@include pc {
		width: 22%;
		margin-bottom: 0;
	}
}
.careers-question__item:nth-of-type(n+3) {
	margin-top: 0;
}
.careers-question__item:last-of-type {
	margin-bottom: 0;
}
.careers-question__item .careers-question__link {
	flex-direction: row;
	justify-content: space-between;
	padding-top: 0;
	padding-right: 0;
	padding-bottom: 0;
	padding-left: 29px;
	text-align: inherit;

	@include pc {
		padding-left: 0;
		text-align: center;
	}
}
.careers-question__item .careers-question__link::after {
	top: calc(50% + 2px);
	right: 14px;
	bottom: auto;
	left: auto;
	width: 7px;
	height: 7px;
	transition: right .3s;
	transform: rotate(-45deg) translateY(-50%);
}
.careers-question__item:hover .careers-question__link::after {
	@include pc {
		right: 12px;
		margin-top: -2px;
	}
}
.careers-question__text {
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 1;
	width: 100%;
	letter-spacing: 0;
}
