@use "../base/summarize" as * ;
.company-business-mv {
	height: 375px;

	@include wide {
		height: 600px;
	}
	&.under-mv {
		height: 280px;
		@include wide {
			height: 400px;
		}
	}
	.under-mv__image {
		img {
			font-family: "object-fit: cover;object-position: 65% 0;";

			object-fit: cover;
			object-position: 65% 0;
		}
	}
}
.company-business-mv__text {
	font-family: $font_barlow_c;
	font-size: 1.4rem;
	display: block;
	margin-top: 18px;

	@include wide {
		font-size: 2.4rem;
		font-weight: 600;
		margin-top: 35px;
		letter-spacing: .05em;
	}
}


.company-business-profile__inner {
	position: relative;
	z-index: 2;
	margin-top: -80px;
	padding: 30px 20px 40px;
	background: #fff;
	box-shadow: 10px 10px 40px rgba(16, 15, 24, .1);

	@include wide {
		max-width: 992px;
		margin-top: -140px;
		margin-right: auto;
		margin-left: auto;
		padding: 40px 56px;
	}
}
.company-business-profile__name {
	font-size: 2.4rem;
	margin-bottom: 15px;

	@include wide {
		letter-spacing: .1em;
	}
	span {
		font-size: 1.6rem;
		font-weight: normal;
		display: block;

		@include wide {
			letter-spacing: .02em;
		}
	}
}


.company-business-menu {
	padding-top: 60px;
	padding-bottom: 60px;

}


.company-business-main {
	padding-top: 80px;
	padding-bottom: 50px;

	@include wide {
		padding-bottom: 80px;
	}
}
.company-business-main__inner {
	@include wide {
		max-width: calc(992px + #{$side_padding_pc} * 2);
	}
}
.company-business-main {
	background: $white;
}
.company-business-main__heading {
	font-size: 2.4rem;
	line-height: 1.5;
	margin-bottom: 35px;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.8rem;
		margin-bottom: 30px;
	}
	&:not(:first-child) {
		margin-top: 130px;

		@include wide {
			margin-top: 100px;
		}
	}
	.company-business-main__organization +&,
	.company-business-main__figure +& {
		margin-top: 80px;

		@include wide {
			margin-top: 116px;
		}
	}
}
.company-business-main__sub-heading {
	font-size: 2rem;
	line-height: 1.5;
	margin-top: 50px;
	margin-bottom: 30px;
	letter-spacing: .05em;
}
.company-business-main__text {
	font-size: 1.8rem;
	line-height: 1.75;

	@include wide {
		line-height: 2;
	}
	& +& {
		margin-top: 40px;
	}
}
.company-business-main__text-link {
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}
.company-business-main__text-url {
	position: relative;
	display: block;
	margin-top: 1rem;
	margin-bottom: 4rem;
	font-size: 1.8rem;
	font-weight: 600;
	line-height: 2;
	letter-spacing: 0.036rem;
	color: $red;
	display: inline-block;
	&::before {
		position: absolute;
		bottom: 2px;
		left: 0;
		display: block;
		width: 100%;
		height: 1px;
		content: "";
		transition: 0.4s opacity;
		opacity: 0;
		background: #e60000;
	}
	&:hover::before {
		opacity: 1;
	}
}
.company-business-main__link {
	font-weight: 600;
}
.company-business-main__indent {
	padding-left: 1em;
	text-indent: -1em;
}
.company-business-main__annotation {
	font-size: 1.2rem;
	margin-top: 10px;
	.company-business-main__link {
		font-weight: normal;
	}
}
.company-business-main__feature {
	margin-top: 40px;

	@include wide {
		display: flex;
	}
	@include tab {
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
		max-width: 590px;
		margin-right: auto;
		margin-left: auto;
	}
}
.company-business-main__feature__block {
	@include tab {
		width: 50%;
		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}
	@include pc {
		width: 100%;
	}
	&:not(:first-child) {
		@include sp {
			margin-top: 50px;
		}
	}
}
.company-business-main__feature__text {
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 1.5;
	margin-top: 25px;
	text-align: center;

	@include wide {
		margin-top: 30px;
	}
	@include pc {
		margin-right: 10px;
		margin-left: 10px;
	}
}
.company-business-main__feature__image {
	position: relative;
	width: 180px;
	margin: 0 auto;

	@include wide {
		width: calc(100% - 50px);
		max-width: 264px;
		margin-right: auto;
		margin-left: auto;
	}
	&:after {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		display: block;
		width: 99%;
		height: 99%;
		content: "";
		border-radius: 50%;
		box-shadow: 10px 10px 40px rgba(16, 15, 24, .1);
	}
	picture {
		position: relative;
		z-index: 1;
	}
	img {
		width: 100%;
	}
}
.company-business-main__figure {
	position: relative;
	margin-top: 40px;
	&--02 {
		border: 1px solid #E3DBDB;
		padding-top: 30px;
	}
}
.company-business-main__notes {
	font-size: 1rem;
	margin-top: 6rem;
	margin-bottom: 30px;
	text-align: center;
	white-space: pre-wrap;

	@include wide {
		font-size: 1.2rem;
		margin-bottom: 35px;
	}
	a {
		font-weight: 600;

		@include wide {
			font-size: 1.4rem;
		}

		overflow-wrap: break-word;
	}
}
.company-business-main__organization {
	margin-top: 50px;
	padding: 25px 20px 0 20px;
	text-align: center;
	border: 1px solid $warm_gray;

	@include wide {
		margin-top: 40px;
		padding: 35px 55px 0 55px;
	}
	.company-business-main__notes {
		@include wide {
			margin-top: 60px;
		}
	}
}
.company-business-main__organization__figure {
	@include wide {
		max-width: 672px;
		margin-right: auto;
		margin-left: auto;
	}
}
.company-business-main__organization__list {
	margin-top: 40px;

	@include wide {
		margin-top: 54px;
	}
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
}
.company-business-main__organization__block {
	margin-top: 40px;

	@include pc {
		width: 47.4%;
		margin-top: 35px;
		&:nth-child(odd) {
			margin-right: 5.2%;
		}
	}
}
.company-business-main__organization__heading {
	font-size: 1.4rem;
	font-weight: normal;
	margin-bottom: 30px;

	@include wide {
		font-size: 2rem;
		margin-bottom: 54px;
		text-align: left;
		letter-spacing: .1em;
	}
}
.company-business-main__organization__sub-heading {
	font-size: 1.8rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	text-align: left;

	@include wide {
		font-size: 2rem;
		letter-spacing: .1em;
	}
	&:before {
		display: block;
		flex: none;
		width: 0.6rem;
		height: 2.8rem;
		margin-right: 1.2rem;
		content: "";

		@include wide {
			height: 3rem;
		}
	}
	&--01 {
		color: #0077AC;
		&:before {
			background-color: #0077AC;
		}
	}
	&--02 {
		color: #03ADAD;
		&:before {
			background-color: #03ADAD;
		}
	}
	&--03 {
		color: #FCB50D;
		&:before {
			background-color: #FCB50D;
		}
	}
	&--04 {
		color: #EE4E68;
		&:before {
			background-color: #EE4E68;
		}
	}
	&--05 {
		color: #58595B;
		&:before {
			background-color: #58595B;
		}
	}
}
.company-business-main__organization__text {
	text-align: left;
	& +& {
		margin-top: 15px;
	}
	em {
		font-size: 1.6rem;
		font-weight: 600;
		font-style: normal;
		display: block;
		margin-bottom: 5px;
		letter-spacing: .02em;
	}
}



.company-business-main__second-heading {
	font-size: 2.4rem;
	line-height: 1.5;
	margin-bottom: 60px;
	letter-spacing: .05em;
	margin-top: 80px;
	padding-top: 80px;
	border-top: 1px solid #C8CDD6;
	@include wide {
		margin-top: 120px;
		padding-top: 120px;
	}
}
.company-business-main__link-list {

}
.company-business-main__link-item {
	margin-bottom: 30px;
}
.company-business-main__link-item a {
	@include wide {
		display: flex;
	}
	&:hover p {
		text-decoration: underline;
	}

}
.company-business-main__link-item time {
	display: block;
	position: relative;
	white-space: nowrap;
	word-break: keep-all;
	font-size: 1.6rem;
	font-weight: 400;
	letter-spacing: 0.036rem;
	margin-right: 2rem;
	padding-left: 1.5rem;
	margin-bottom: 5px;
	@include wide {
		font-size: 1.8rem;
		margin-bottom: 0;
	}
	&::before {
		position: absolute;
		content: '';
		top: 13px;
		left: 0;
		transform: translateX(-50%) rotate(45deg);
		width: 7px;
		height: 7px;
		border: 1px solid $red;
		border-bottom: none;
		border-left: none;
	}
}