@use "../base/summarize" as * ;

// 共通
.content-block__body {
    margin: 0 auto;
    @include pc {
        max-width: calc(1200px + 48px* 2);
    }
}
.content-block__area {
    padding: 0 2rem;
    @include pc {
        padding: 0;
    }
}
.screen-black::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.3;
    background: #100F18;
    z-index: 1;
}

.job-list {
    padding: 2.4rem 0 6rem;
    @include pc {
        padding: 7.4rem 0 10rem;
    }
    &__heading {
        text-align: center;
        font-size: 2.8rem;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: 0.14rem;
        margin-bottom: 3.6rem;
        @include pc {
            font-size: 3.6rem;
            line-height: 175%
        }
    }
    &__filter-block {
        @include pc {
            display: flex;
            align-items: center;
            margin-bottom: 2.8rem;
        }
    }
    &__filter-area {
        padding: 0 0 2.8rem 2rem;
        @include pc {
            padding-bottom: 0;
        }
    }
    &__filter-btn {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        font-family: inherit;
        text-align: inherit;
        color: $black;
        font-size: 1.3rem;
        font-weight: 700;
        @include pc {
            margin-right: 2rem;
            flex-shrink: 1;
            cursor: pointer;
        }
        &::before {
            display: block;
            content: '';
            background-image: url("/assets/img/common/icon-filter.svg");
            background-size: cover;
            background-repeat: no-repeat;
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 0.5rem;
        }
    }
    &__term-list {
        display: flex;
        overflow-x: auto;
        width: 100%;
        white-space: nowrap;
        margin-bottom: 2.8rem;
        @include pc {
            width: auto;
            margin-bottom: 0;
        }
    }
    &__term-item {
        position: relative;
        background-color: #EBEBEB;
        width: fit-content;
        min-height: 3.2rem;
        margin-right: 1rem;
        flex-shrink: 0;
        &::after {
            position: absolute;;
            top: 50%;
            right: 0.5rem;
            transform: translateY(-50%);
            display: block;
            content: '';
            background-image: url(/assets/img/common/close-btn-black.svg);
            background-size: cover;
            background-repeat: no-repeat;
            width: 0.9rem;
            height: 0.9rem;
        }
    }
    &__term-item-text {
        color: $black;
        font-size: 1rem;
        font-weight: 700;
        line-height: 100%;
    }
    &__term-item-btn {
        all: unset;
        display: flex;
        cursor: pointer;
        padding: 1rem 1.8rem 1rem 0.5rem;
    }
    &__list {
        margin-bottom: 4rem;
        @include pc {
            margin-bottom: 6rem;
        }
    }
    &__recruitment-btn {
        width: 26.4rem;
        margin: 0 auto;
    }
    &__recruitment-btn-text {
        display: flex;
        &::after {
            position: relative;
            top: 0.6rem;
            display: block;
            content: '';
            background-image: url("/assets/img/common/icon-external-white.svg");
            background-size: cover;
            background-repeat: no-repeat;
            width: 0.75rem;
            height: 0.75rem;
            margin-left: 0.5rem;
        }
    }
    &__none-text {
        text-align: center;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 175%;
        letter-spacing: 0.032rem;
        margin-bottom: 4rem;
        @include pc {
            padding-top: 4.4rem;
            margin-bottom: 6rem;
        }
    }
    &__modal {
        display: none;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 1rem 0;
        background-color: $white;
        width: 35.5rem;
        z-index: 2;
        &--open {
            display: block;
        }
        @include pc {
            width: 47.2rem;;
        }
    }
    &__modal-title {
        color: $black;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 700;
        padding-bottom: 1rem;
    }
    &__modal-list {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #C8CDD6;
        border-bottom: 1px solid #C8CDD6;
        padding: 1rem 0;
        @include pc {
            padding: 2rem 0;
        }
    }
    &__modal-item {
        width: calc(100% / 2);
        padding: 1rem 0.5rem 1rem 1rem;
        @include pc {
            padding: 1.6rem 1rem 1.6rem 3rem;
        }
        &:nth-child(even) {
            padding: 1rem 1rem 1rem 0.5rem;
            @include pc {
                padding: 1.6rem 1.6rem 1.6rem 1rem;
            }
        }
    }
    &__modal-label {
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        font-weight: 700;
        line-height: 140%;
        padding: 0;
        @include pc {
            font-size: 1.4rem;
        }
    }
    &__modal-checkbox {
        flex-shrink: 0;
        appearance: none;
        width: 1.6rem;
        height: 1.6rem;
        border: 1px solid #C8CDD6;
        background-color: $white;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        transition: all 0.3s ease;
        margin: 0 0.5rem 0 0;
        &:checked {
            background-image: url('/assets/img/common/icon-check-red.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 1.6rem;
            height: 1.6rem;
        }
    }
    &__modal-btn-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 27.2rem;
        margin: 0 auto;
        padding-top: 1rem;
    }
    &__modal-btn {
        display: inline-block;
        padding: 1rem 2rem;
        color: $black;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 700;
        background-color: $white;
        border: none;
        min-height: 4rem;
        border-radius: 2rem;
        cursor: pointer;
    }
    &__modal-narrow-btn {
        border: 1px solid $red;
    }
}

.job-flow {
    background-color: $white;
    &__body {
        padding: 6rem 0 8rem;
        @include pc {
            padding: 10rem 0;
        }
    }
    &__content {
        @include pc {
            padding: 0 4.8rem;
        }
    }
    &__heading {
        text-align: center;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: 0.24rem;
        margin-bottom: 2.8rem;
        @include pc {
            font-size: 2.8rem;
            margin-bottom: 6rem;
        }
    }
    &__list {
        position: relative;
        z-index: 2;
        counter-reset: list-counter;
        list-style: none;
        margin: 0 auto;
        @include pc {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4rem;
        }
    }
    &__item {
        position: relative;
        counter-increment: list-counter;
        position: relative;
        padding: 0 0 2.2rem 6rem;
        margin-bottom: 2.2rem;
        width: 100%;
        @include pc {
            width: calc((100% - 8rem) / 5);
            padding: 8rem 4rem 3rem 0;
            margin-bottom: 0;
        }
        &::before {
            display: flex;
            align-items: center;
            justify-content: center;
            content: "0" counter(list-counter);
            position: absolute;
            left: 0;
            top: 0;
            color: $white;
            font-size: 1.4rem;
            font-weight: 700;
            letter-spacing: 0.028rem;
            background-color: $black;
            width: 4rem;
            height: 4rem;
            border-radius: 2rem;
            @include pc {
                top: 3rem;
                left: calc(50% - 2rem);
                transform: translateX(-50%);
            }
        }
        &::after {
            position: absolute;
            bottom: -2.2rem;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            content: '';
            background-image: url(/assets/img/careers/jobs/jobs-flow-arrow.png);
            background-repeat: no-repeat;
            background-size: cover;
            width: 33.5rem;
            height: 2.2rem;
            @include pc {
                left: auto;
                right: -2rem;
                background-image: url(/assets/img/careers/jobs/jobs-flow-arrow-pc.png);
                width: 2rem;
                height: 25rem;
            }
        }
        &:last-of-type::after {
            display: none;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        &--one {
            padding: 0.6rem 0 2.6rem 6rem;
            @include pc {
                padding: 9rem 0 3rem 0;
            }
            &::before {
                @include pc {
                    left: 50%;
                }
            }
        }
    }
    &__item-title {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 175%;
        letter-spacing: 0.032rem;
        @include pc {
            text-align: center;
            font-size: 1.8rem;
            line-height: 130%;
            letter-spacing: 0.036rem;
            margin-bottom: 2rem;
        }
    }
    &__item-title-small {
        color: $black;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 175%;
        letter-spacing: 0.024rem;
    }
    &__item-text {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.024rem;
        padding-top: 0.4rem;
        @include pc {
            font-size: 1.5rem;
            letter-spacing: 0.03rem;
            text-align: center;
        }
    }
    &__notes-text {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.024rem;
        @include pc {
            font-size: 1.4rem;
            letter-spacing: 0.028rem;
        }
    }
}

.job-qa {
    &__body {
        padding: 6rem 0;
        @include pc {
            padding: 10rem 0 12rem;
        }
    }
    &__content {
        @include pc {
            padding: 0 4.8rem;
        }
    }
    &__heading {
        text-align: center;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: 0.24rem;
        margin-bottom: 2.8rem;
        @include pc {
            font-size: 2.8rem;
            letter-spacing: 0.28rem;
            margin-bottom: 8rem;
        }
    }
    &__list {
        @include pc {
            display: flex;
            justify-content: space-between;
        }
    }
    &__item {
        position: relative;
        margin-bottom: 1rem;
        @include pc {
            width: calc((100% - 14.4rem) / 4);
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        &::after {
            position: absolute;
            top: 50%;
            right: 1.5rem;
            display: block;
            width: 0.7rem;
            height: 0.7rem;
            content: "";
            transform: rotate(-45deg) translateY(-50%);
            border: solid #e60000;
            border-width: 0 0.1rem 0.1rem 0;
        }
    }
    &__link {
        display: flex;
        align-items: center;
        color: $black;
        font-size: 1.6rem;
        font-weight: 700;
        height: 6rem;
        padding: 0 3rem;
        border: 1px solid #E3DBDB;
        @include pc {
            padding: 0 5rem;
        }
    }
}

.job-return {
    background-color: $white;
    padding: 5rem 0;
    @include pc {
        padding: 10rem 0;
    }
    &__recruitment-btn {
        background-color: $white;
        margin: 0 auto;
    }
    &__recruitment-btn-text {
        color: $red;
    }
}